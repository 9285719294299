import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import colors from '../utils/theme/colors'

const CallToAction = ({ title, link }) => {
  const { _type } = link[0]

  if (_type === 'externalLink') {
    const { href, blank } = link[0]
    return (
      <S.ExternalLink
        href={href}
        target={blank ? '_blank' : ''}
        rel='noreferrer noopener nofollow'
      >
        {title}
      </S.ExternalLink>
    )
  }

  if (_type === 'internalLink') {
    const iLink = link[0].link.slug.current
    return <S.Link to={`/${iLink !== '/' ? iLink : ''}`}>{title}</S.Link>
  }

  return null
}

export default CallToAction

const S = {}

const ctaButtonMixin = css`
  border: 2px solid ${() => colors.purpleLight};
  padding: 0.65rem 1.5rem;
  border-radius: 7px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Proza Libre';
  font-size: 0.8rem;
  color: ${() => colors.purpleLight};
  transition: all 100ms ease-in-out;
  position: relative;
`

S.Link = styled(Link)`
  ${ctaButtonMixin}
`

S.ExternalLink = styled.a`
  ${ctaButtonMixin}
`
