import React from 'react'
import styled from 'styled-components'

import BlockContent from '../components/block-content'

const TextSection = ({ node }) => {
  return (
    <Section>
      <h3>{node.heading}</h3>
      <h4>{node.label}</h4>
      <BlockContent blocks={node.text} />
    </Section>
  )
}

export default TextSection

const Section = styled.section``
