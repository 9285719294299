import React from 'react'
import styled from 'styled-components'

import BlockContent from '../components/block-content'
import CTA from '../components/cta'
import urlFor from '../utils/urlFor'

const Hero = ({ backgroundImage, cta, heading, tagline }) => {
  return (
    <S.Hero>
      <img src={urlFor(backgroundImage).url()} />
      <h1>{heading}</h1>
      <BlockContent blocks={tagline} />
      {cta && <CTA {...cta} />}
    </S.Hero>
  )
}

export default Hero

const S = {}
S.Hero = styled.section`
  margin: 0 auto 4rem;
`
