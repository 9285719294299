import requireConfig from '../../require-config'
import imageurlBuilder from '@sanity/image-url'

const { api } = requireConfig()

const builder = imageurlBuilder(api)

const urlFor = source => {
  return builder.image(source)
}

export default urlFor
