import React from 'react'
import { useSpring, animated } from 'react-spring'
import { useInView } from 'react-intersection-observer'

const AppearSection = ({ children }) => {
  const [ref, inView] = useInView({ rootMargin: '32px' })
  const appear = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'traslateY(0)' : 'translateY(100px)'
  })

  return (
    <animated.section ref={ref} style={appear}>
      {children}
    </animated.section>
  )
}

export default AppearSection
