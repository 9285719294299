import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const InternalLink = ({ children, to }) => {
  return <Link to={to === '/' ? '/' : `/${to}`}>{children}</Link>
}

export default InternalLink

InternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
}
