import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import BlockContent from '../components/block-content'

const IndexPage = ({
  data: {
    page: { title, _rawContent }
  }
}) => {
  return (
    <Layout>
      <BlockContent blocks={_rawContent} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      _rawContent(resolveReferences: { maxDepth: 8 })
    }
  }
`
