import { useState } from 'react'

const useForm = (callback, initialState = {}) => {
  const [values, setValues] = useState(initialState)

  const handleSubmit = event => {
    if (event) event.preventDefault()
    callback()
  }

  const handleChange = event => {
    event.persist()
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }))
  }

  const reset = () => {
    setValues(initialState)
  }

  return {
    handleChange,
    handleSubmit,
    reset,
    values
  }
}

export default useForm
