import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { useInView } from 'react-intersection-observer'

import BlockContent from '../components/block-content'
import CTA from '../components/cta'

import urlFor from '../utils/urlFor'
import media from '../utils/theme/media'

const ImageSection = ({ heading, cta, text, image }) => {
  const [ref, inView] = useInView({ rootMargin: '20px' })
  const appear = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'traslateY(0)' : 'translateY(100px)'
  })

  return (
    <S.Section ref={ref} style={appear}>
      <S.Image>
        <img
          src={urlFor(image)
            .height(420)
            .width(300)
            .url()}
        />
      </S.Image>
      <S.Text>
        <h2>{heading}</h2>
        <BlockContent blocks={text} />
        {cta && <CTA {...cta} />}
      </S.Text>
    </S.Section>
  )
}

export default ImageSection

const S = {}

S.Section = styled(animated.section)`
  margin: 4rem 0;
  ${media.tablet`
    display: flex;
    flex-direction: row;
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  `}
`

S.Text = styled.div`
  width: 90%;
  ${media.tablet`
    width: 45%;
    margin: auto;
  `}
`

S.Image = styled.div`
  text-align: center;
  max-width: 75%;
  margin: auto;
  margin-bottom: 2rem;
  img {
    width: 100%;
    margin-bottom: 0;
  }
  ${media.tablet`
    width: 45%;
    margin-bottom: auto;
  `}
`
