import React from 'react'
import styled from 'styled-components'
import axios from 'axios'

import Button from '../elements/Button'
import useForm from '../hooks/useForm'

import colors from '../utils/theme/colors'

const SlackForm = ({ id, title, channel = '', formFields, submitText }) => {
  const initialState = {}
  const fields = formFields.map(field => field.fieldName)
  fields.forEach(field => {
    initialState[field] = ''
  })

  const submit = () => {
    axios
      .post('/.netlify/functions/notify-slack', { values, channel })
      .then(res => {
        console.log(res)
        reset()
      })
      .catch(console.log)
  }

  const { values, reset, handleChange, handleSubmit } = useForm(
    submit,
    initialState
  )

  return (
    <Form onSubmit={handleSubmit}>
      {formFields.map(({ _key, fieldName, fieldType }) => (
        <FieldSet key={_key}>
          <Label htmlFor={_key}>{fieldName}</Label>
          {fieldType === 'input' && (
            <Input
              id={_key}
              name={fieldName}
              value={values[fieldName]}
              onChange={handleChange}
            />
          )}
          {fieldType === 'textarea' && (
            <Textarea
              id={_key}
              rows='4'
              name={fieldName}
              value={values[fieldName]}
              onChange={handleChange}
            />
          )}
        </FieldSet>
      ))}
      <Button type='submit'>{submitText}</Button>
    </Form>
  )
}

export default SlackForm

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 32rem;
  margin: 3rem auto;
  background-color: ${() => colors.purpleLight};
  color: ${() => colors.white};
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
`

const FieldSet = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem;
`

const Label = styled.label`
  font-family: 'Proza Libre';
  font-size: 0.8rem;
`

const Input = styled.input`
  border: none;
  border-bottom: solid 1px ${() => colors.white};
  background-color: transparent;
  color: ${() => colors.white};
`

const Textarea = styled.textarea`
  resize: none;
  border: none;
  background-color: transparent;
  color: ${() => colors.white};
  border-bottom: solid 1px ${() => colors.white};
`
