import React from 'react'

import Hero from '../sections/hero'
import ImageSection from '../sections/image-section'
import TextSection from '../sections/text-section'
import FormSection from '../sections/form-section'
import PeopleSection from '../sections/people-section'

import ExternalLink from '../elements/ExternalLink'
import InternalLink from '../elements/InternalLink'

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    hero: props => <Hero {...props.node} />,
    imageSection: ({ node }) => <ImageSection {...node} />,
    textSection: props => <TextSection {...props} />,
    formSection: ({ node }) => <FormSection {...node} />,
    peopleSection: ({ node }) => <PeopleSection {...node} />
  },
  marks: {
    externalLink: ({ children, mark: { href, blank } }) => (
      <ExternalLink href={href} blank={blank}>
        {children}
      </ExternalLink>
    ),
    internalLink: ({
      children,
      mark: {
        link: { slug }
      }
    }) => (
      <InternalLink to={`/${slug ? slug.current : null}`}>
        {children}
      </InternalLink>
    )
  }
}

export default serializers
