import React from 'react'
import styled from 'styled-components'

import colors from '../utils/theme/colors'

const Button = ({ children }) => {
  return <S.Button>{children}</S.Button>
}

export default Button

const S = {}
S.Button = styled.button`
  background: ${() => colors.red};
  color: ${() => colors.white};
  text-transform: uppercase;
  font-family: 'Proza Libre';
  outline: none;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  transition: all 150ms ease-in-out;

  :hover {
    background-color: ${() => colors.redDark};
  }
`
