import { useSpring } from 'react-spring'
import { useInView } from 'react-intersection-observer'

const useAppear = () => {
  const [ref, inView] = useInView({ rootMargin: '32px' })
  const appearStyles = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(100px)'
  })

  return [ref, appearStyles]
}

export default useAppear
