const ScreenSizes = {
  DESKTOP: 992,
  TABLET: 768,
  PHONE: 576
}

export default {
  desktop: ScreenSizes.DESKTOP,
  tablet: ScreenSizes.TABLET,
  phone: ScreenSizes.PHONE
}
