import React from 'react'
import styled from 'styled-components'
import { animated } from 'react-spring'
import {
  FaEnvelope,
  FaFacebook,
  FaGithub,
  FaLinkedin,
  FaPhone,
  FaTwitter,
  FaGlobe
} from 'react-icons/fa'
import { MdPerson } from 'react-icons/md'

import useAppear from '../hooks/useAppear'
import BlockContent from './block-content'
import ExternalLink from '../elements/ExternalLink'

import urlFor from '../utils/urlFor'
import colors from '../utils/theme/colors'
import media from '../utils/theme/media'

const person = ({
  firstName,
  lastName,
  title,
  bio,
  keywords,
  profilePicture,
  _rawProfilePicture,
  email,
  facebook,
  github,
  linkedin,
  phone,
  twitter,
  website,
  ...props
}) => {
  const [ref, appear] = useAppear()

  return (
    <S.Person ref={ref} style={appear}>
      <S.Container>
        <S.Image>
          {profilePicture ? (
            <img
              src={urlFor(profilePicture)
                .height(420)
                .width(300)
                .url()}
            />
          ) : (
            <MdPerson size={300} />
          )}
        </S.Image>
        <S.Info>
          <h3>
            {firstName} {lastName}
          </h3>
          <h4>{title}</h4>
          {keywords && (
            <S.Keywords>
              {keywords.map(word => (
                <S.Keyword key={word}>{word}</S.Keyword>
              ))}
            </S.Keywords>
          )}
          {(email ||
            facebook ||
            github ||
            linkedin ||
            phone ||
            twitter ||
            website) && (
            <S.Links>
              {website && (
                <S.ExternalLink blank href={website}>
                  <FaGlobe />
                </S.ExternalLink>
              )}
              {email && (
                <S.ExternalLink href={`mailto:${email}`}>
                  <FaEnvelope />
                </S.ExternalLink>
              )}
              {phone && (
                <S.ExternalLink href={`tel:${phone}`}>
                  <FaPhone />
                </S.ExternalLink>
              )}
              {linkedin && (
                <S.ExternalLink blank href={linkedin}>
                  <FaLinkedin />
                </S.ExternalLink>
              )}
              {github && (
                <S.ExternalLink blank href={github}>
                  <FaGithub />
                </S.ExternalLink>
              )}
              {facebook && (
                <S.ExternalLink blank href={facebook}>
                  <FaFacebook />
                </S.ExternalLink>
              )}
              {twitter && (
                <S.ExternalLink blank href={twitter}>
                  <FaTwitter />
                </S.ExternalLink>
              )}
            </S.Links>
          )}
        </S.Info>
      </S.Container>
      <BlockContent blocks={bio} />
    </S.Person>
  )
}

export default person

const S = {}

S.Person = styled(animated.div)`
  margin: 4rem 0;
`

S.Container = styled.div`
  ${media.tablet`
    display: flex;
    flex-direction: row;
  `}
`

S.Image = styled.div`
  max-width: 300px;
  margin-bottom: 2rem;
  img {
    width: 100%;
    margin-bottom: 0;
  }
  ${media.tablet`
    width: 35%;
    margin-bottom: auto;
  `}
`

S.Info = styled.div`
  width: 100%;
  ${media.tablet`
    width: 50%;
    margin: auto;
  `}
`

S.Keywords = styled.div`
  margin: 1.5rem 0;
`

S.Keyword = styled.span`
  background-color: ${colors.redDark};
  color: ${colors.white};
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 1.2rem;
  display: inline-block;
`

S.Links = styled.div`
  margin: 1.5rem 0;
`

S.ExternalLink = styled(ExternalLink)`
  margin: 0.5rem;
  font-size: 1.5rem;
`
