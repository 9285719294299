import React from 'react'

import BlockContent from '../components/block-content'
import SlackForm from '../components/slack-form'
import AppearSection from '../elements/AppearSection'

const FormSection = ({ title, text, form }) => {
  return (
    <AppearSection>
      <h2>{title}</h2>
      <BlockContent blocks={text} />
      <SlackForm {...form} />
    </AppearSection>
  )
}

export default FormSection
