import React from 'react'

import BlockContent from '../components/block-content'
import Person from '../components/person'

const PeopleSection = ({ heading, text, people }) => {
  return (
    <section>
      {heading && <h2>{heading}</h2>}
      {text && <BlockContent blocks={text} />}
      {people && (
        <div>
          {people.map(person => (
            <Person key={person.id} {...person} />
          ))}
        </div>
      )}
    </section>
  )
}

export default PeopleSection
